<template>
  <div class="top">
    <template v-if="sonUser === 0">
      <div
        v-if="act !== 2 && act === 0"
        @click="setAct(0)"
        :class="act === 0 ? 'reg active' : 'reg'"
      >
        手机号注册
      </div>
      <!--      <div-->
      <!--        v-if="act !== 2 && act===1"-->
      <!--        @click="setAct(1)"-->
      <!--        :class="act === 1 ? 'log active' : 'log'"-->
      <!--      >-->
      <!--        登录-->
      <!--      </div>-->
      <div v-if="act === 2" :class="act === 1 ? 'remove' : 'remove'">
        忘记密码
      </div>
    </template>
    <template v-else><div class="Sonuser">子账号登录</div> </template>
  </div>
</template>

<script>
export default {
  name: "top",
  props: {
    act: {
      default: 0,
      type: Number,
    },
    sonUser: {
      default: 0,
      tyroe: Number,
    },
  },
  methods: {
    setAct(i) {
      this.$emit("cahngeAct", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  width: 300px;
  justify-content: space-around;
  align-items: center;

  //background: pink;
  margin: 0 auto;
  //margin-top: 80px;
  padding-top: 35px;

  .active {
    height: 23px;
    font-size: 24px;
    font-weight: bold;
    color: #263d78;
    line-height: 24px;
  }
  .Sonuser {
    width: 120px;
    //background: yellow;
  }
  div {
    height: 25px;
    font-size: 24px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }

  .reg {
    margin-right: 180px;
    cursor: pointer;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .remove {
    margin-right: 10px;
    cursor: pointer;
    width: 200px;
    //background: pink;
    text-align: center;
  }
  .log {
    margin-left: 10px;
    cursor: pointer;
    //background: pink;
  }
}
</style>
