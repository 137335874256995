<template>
    <div class="verify-login" :style="wrapperStyle" ref="VerifyLoginRef">
        <div v-if="x > 2 && !status" class="moved-block" :style="{ width: `${x}px`, background: `${primaryColor}` }" />
        <div v-else class="content">{{ status ? '验证通过' : '请按动滑块,拖至最右' }}</div>
        <img class="block" :src="blockSrc" :draggable="false" :style="blockStyle" @mousedown="handleMouseDown" />
    </div>
</template>

<script>
import checkedIcon from '@/assets/img/login/checked-icon.png';
import checkedSuccess from '@/assets/img/login/checked-success.png';

export default {
    data() {
        return {
            x: 0,
            status: false,
            mouseDownX: 0,
        };
    },
    props: {
        width: { default: 300 },
        height: { default: 40 },
        primaryColor: { default: '#4a79f1' },
    },
    computed: {
        blockSrc() {
            return this.status ? checkedSuccess : checkedIcon;
        },
        wrapperStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.height}px`,
                color: this.status ? '#FFF' : '#333',
                backgroundColor: this.status ? this.primaryColor : 'rgba(0, 0, 0, 0.04)',
            };
        },
        blockStyle() {
            return {
                left: `${this.x}px`,
                width: `${this.height}px`,
                height: `${this.height}px`,
            };
        },
    },
    methods: {
        /* 鼠标按下去 */
        handleMouseDown(e) {
            if (this.status) return;
            this.mouseDownX = e.pageX;
            window.addEventListener('mouseup', this.handleMouseUp);
            window.addEventListener('mousemove', this.onListenMouseMove);
        },
        /* 鼠标抬起 */
        handleMouseUp() {
            if (this.status) return;
            this.x = 0;
            this.mouseDownX = 0;
            this.removeListen();
        },
        /* 监听滑动 */
        onListenMouseMove(e) {
            const moveX = e.pageX - this.mouseDownX;
            const moveScope = this.width - this.height;

            // 触发最大距离
            if (moveX >= moveScope) {
                this.status = true;
                this.x = moveScope;
                this.removeListen();
                setTimeout(() => {
                    this.$emit('success');
                }, 500);
                return;
            }

            // 触发最小距离
            if (moveX <= 0) {
                this.x = 0;
                this.status = false;
                return;
            }

            // 正常拖转
            this.x = moveX;
        },
        /* 移除监听 */
        removeListen() {
            window.removeEventListener('mouseup', this.handleMouseUp);
            window.removeEventListener('mousemove', this.onListenMouseMove);
        },
        /* 重置滑块 */
        resetStatus() {
            this.x = 0;
            this.status = false;
        },
    },
};
</script>

<style lang="scss">
.verify-login {
    width: 300px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    .content {
        font-size: 14px;
    }
    .moved-block {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 6px;
    }
    .block {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        position: absolute;
        top: 0px;
        box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2);
    }
}
</style>
