<template>
  <div></div>
  <!--  <div @click="setSonUser" v-if="act === 1" class="foot">-->
  <!--    {{ sonUser === 0 ? "子账号登录" : "主账号登录" }}-->
  <!--  </div>-->
</template>

<script>
export default {
  name: "footers",
  props: ["act", "sonUser"],
  methods: {
    setSonUser() {
      this.$emit("SetsonUser", this.sonUser === 0 ? 1 : 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.foot {
  width: 100%;
  //background: pink;
  cursor: pointer;
  height: 50px;
  margin: 0 auto;
  border-top: 3px solid #121b3d;
  opacity: 0.41;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  //font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  //border-radius: 10px;
}
</style>
