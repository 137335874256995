import zwitCore from "zw-core";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
//忘记密码
export function repwd(data) {
  return zwitCore.HTTP.post(
    BASE_URL + "/zw-user/reset/nf/scholarRetrievePwd",
    data,
    false
  );
}
//子账号登录 中文知识网的
export function sonLogin(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-user/subUser/nf/login",
    data,
    false
  );
}
//登录注册
export function login(data) {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/bj/login", data, false);
}
export function reg(data) {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/register/user", data, false);
}

export function logOut() {
  return zwitCore.HTTP.json(BASE_URL + "/zw-user/logout");
}