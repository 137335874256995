<template>
  <div class="content" v-if="flag">
    <template v-if="sonUser === 0">
      <!-- 登录 -->
      <div v-if="act === 1">
        <div class="top">
          <div
            style="cursor: pointer"
            @click="setAct(0)"
            :class="contentAct === 0 ? 'act' : ''"
          >
            验证码登录
          </div>
          <div
            style="cursor: pointer"
            @click="setAct(1)"
            :class="contentAct === 1 ? 'act' : ''"
          >
            密码登录
          </div>
        </div>
        <!-- 密码登录 -->
        <div class="pwdLogin" v-if="contentAct === 1">
          <div class="phoneDiv">
            <div class="input">
              <Input
                name="loginPhone"
                autocomplete="username"
                v-model="formInline.phone"
                placeholder="请输入手机号"
                type="text"
              />
            </div>
          </div>
          <div class="phoneDiv">
            <div class="input">
              <Input
                name="loginPwd"
                v-model="formInline.password"
                placeholder="请输入密码"
                type="password"
              />
            </div>
          </div>
          <div class="phoneDiv">
            <VerifyLogin ref="passwordLoginVerify" @success="handleVerifySuccess" />
          </div>
          <div class="getPwd">
            <span @click="removePwd">忘记密码?</span>&nbsp;&nbsp;<span
              @click="regUser"
              >注册账号</span
            >
          </div>
          <Button v-throttleClick @click="login" class="sub">登录</Button>
        </div>
        <!-- 验证码登录 -->
        <div class="codeLogin" v-else>
          <div class="phoneDiv">
            <!--            <div class="img"><img src="@/assets/img/login/1.png" alt="" /></div>-->
            <div class="input">
              <input
                v-model="formInline.getCodePhone"
                placeholder="请输入手机号"
                type="text"
              />
            </div>
          </div>
          <div class="phoneDiv" v-if="formInline.isVerify">
            <!--            <div class="img"><img src="@/assets/img/login/3.png" alt="" /></div>-->
            <div class="input" style="width: 160px">
              <input
                v-model="formInline.code"
                placeholder="请输入验证码"
                style="width: 160px;"
                type="text"
              />
            </div>
            <Button @click="getCode('login')" v-throttleClick="2000" class="getCode">
              {{ keyButton }}
            </Button>
          </div>
          <div class="phoneDiv" v-else>
            <VerifyLogin @success="handleVerifySuccess" />
          </div>
          <div class="getPwd"><span @click="regUser">注册账号</span></div>
          <Button v-throttleClick class="sub" @click="codeLogin">登录</Button>
        </div>
      </div>
      <!-- 注册 -->
      <div v-if="act === 0">
        <div class="regiter">
          <div class="phoneDiv">
            <!--            <div class="img"><img src="@/assets/img/login/1.png" alt="" /></div>-->
            <div class="input">
              <input
                v-model="regForm.phone"
                placeholder="请输入手机号"
                type="text"
              />
              <!-- <input name="phone" type="text" style="width: 0; opacity: 0; border: none" /> -->
            </div>
          </div>
          <div class="phoneDiv">
            <div class="input">
              <Input
                v-model="regForm.pwd"
                placeholder="请输入密码"
                type="text"
                :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline' "
                :class="showEye ? '' : 'no-autofill-pwd'"
                @on-click="iconClick"
              />
              <!-- <input name="pwd" type="text" style="width: 0; opacity: 0; border: none" /> -->
            </div>
          </div>
          <div class="phoneDiv1" v-if="regForm.isVerify">
            <!--            <div class="img"><img src="@/assets/img/login/3.png" alt="" /></div>-->
            <div class="input">
              <input
                v-model="regForm.code"
                placeholder="请输入验证码"
                type="text"
              />
              <Button v-throttleClick="2000" @click="getCode('regiter')" class="getCode">
                {{ keyButton }}
              </Button>
            </div>
          </div>
          <div class="phoneDiv" v-else>
            <VerifyLogin @success="handleVerifySuccess1" />
          </div>
          <div @click="setSingle" class="tiaokuan">
            <Radio v-model="single"
              >我已阅读并同意<span @click="look">《用户使用条款》</span></Radio
            >
          </div>
          <Button v-throttleClick class="sub" @click="register">注册</Button>
          <div class="tits">
            已有账号，马上<span @click="goLogin">登录</span>
          </div>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div v-if="act === 2">
        <div class="regiter">
          <div class="phoneDiv">
            <!--            <div class="img"><img src="@/assets/img/login/1.png" alt="" /></div>-->
            <div class="input">
              <input
                v-model="removeFrom.phone"
                placeholder="请输入手机号"
                type="text"
              />
            </div>
          </div>
          <div class="phoneDiv">
            <!--            <div class="img"><img src="@/assets/img/login/2.png" alt="" /></div>-->
            <div class="input">
              <Input
                v-model="removeFrom.pwd"
                placeholder="请输入新密码"
                type="text"
                :icon="showEye ? 'ios-eye-outline' : 'ios-eye-off-outline' "
                :class="showEye ? '' : 'no-autofill-pwd'"
                @on-click="iconClick"
              />
              <!-- <input name="pwd1" type="text" style="width: 0; opacity: 0; border: none" /> -->
            </div>
          </div>
          <div class="phoneDiv1" v-if="removeFrom.isVerify">
            <!--            <div class="img"><img src="@/assets/img/login/3.png" alt="" /></div>-->
            <div class="input">
              <input
                v-model="removeFrom.code"
                placeholder="请输入验证码"
                type="text"
              />
              <Button v-throttleClick="2000" @click="getCode('retrieve_pwd')" class="getCode">
                {{ keyButton }}
              </Button>
            </div>
          </div>
          <div class="phoneDiv" v-else>
            <VerifyLogin @success="handleVerifySuccess2" />
          </div>
          <Button
            v-throttleClick
            style="margin-top: 20px"
            class="sub"
            @click="getPwd"
          >
            找回密码
          </Button>
        </div>
      </div>
    </template>
    <!-- 子账户登录 -->
    <template v-else>
      <div class="pwdLogin">
        <div class="phoneDiv">
          <!--          <div class="img"><img src="@/assets/img/login/5.png" alt="" /></div>-->
          <div class="input">
            <Input
              v-model="sonUserFrom.username"
              placeholder="请输入用户名"
              type="text"
            />
          </div>
        </div>
        <div class="phoneDiv">
          <!--          <div class="img"><img src="@/assets/img/login/2.png" alt="" /></div>-->
          <div class="input">
            <Input
              v-model="sonUserFrom.password"
              placeholder="请输入密码"
              type="password"
            />
          </div>
        </div>
        <div @click="Userlogin" class="sub">登录</div>
      </div>
    </template>
  </div>
</template>

<script>
import { login, reg, repwd, sonLogin } from "@/apis/login";
import { getCode } from "../../../apis/public";
import VerifyLogin from "./VerifyLogin.vue"

export default {
  name: "contents",
  props: ["act", "sonUser", "flag"],
  components: {
    VerifyLogin
  },
  watch: {
    act: function () {
      this.resetVerify();
      this.destroy();
    },
    flag(old, news) {
      if (!news) {
        this.sonUserFrom.username = "";
        this.sonUserFrom.password = "";
        this.formInline.phone = "";
        this.formInline.password = "";
        this.formInline.code = "";
        this.formInline.getCodePhone = "";
        this.removeFrom.pwd = "";
        this.removeFrom.phone = "";
        this.removeFrom.code = "";
        this.regForm.pwd = "";
        this.regForm.phone = "";
        this.regForm.code = "";
        this.resetVerify();
      }
    },
  },
  created() {
    // const _this = this;
    // document.onkeydown = function (e) {
    //   let key = e.keyCode;
    //   if (key == 13) {
    //     _this.login();
    //   }
    // };
  },
  data() {
    return {
      showEye: false,
      single: false,
      contentAct: 0,
      sonUserFrom: {
        username: "",
        password: "",
        isVerify: false,
      },
      formInline: {
        phone: "",
        password: "",
        code: "",
        getCodePhone: "",
        isVerify: false,
      },
      removeFrom: {
        pwd: "",
        phone: "",
        code: "",
        isVerify: false,
      },
      regForm: {
        pwd: "",
        phone: "",
        code: "",
        isVerify: false,
      },
      keyButton: "获取验证码",
      keyFlag: true,
      timer: null,
    };
  },
  methods: {
    iconClick() {
      this.showEye = !this.showEye
    },
    regUser() {
      this.$emit("cahngeAct", 0);
    },
    look() {
      window.open("/clause", "_blank");
    },
    async getPwd() {
      if (!this.removeFrom.phone) {
        this.destroy()
        this.removeFrom.isVerify = false
        this.$Message.warning("手机号不能为空");
        return;
      }
      if (!this.removeFrom.pwd) {
        this.destroy()
        this.removeFrom.isVerify = false
        this.$Message.warning("新密码不能为空");
        return;
      }
      if (!this.removeFrom.code) {
        this.$Message.warning("验证码不能为空");
        return;
      }
      let obj = {
        mobile: this.removeFrom.phone,
        pwd: this.removeFrom.pwd,
        validateCode: this.removeFrom.code,
      };
      let res = await repwd(obj);
      if (res.resultCode !== "200") {
        this.destroy()
        this.removeFrom.isVerify = false
        this.$Message.error(res.resultDesc);
        return;
      }
      this.$Message.success("修改成功");
      this.$emit("cahngeAct", 1);
    },
    removePwd() {
      this.removeFrom.pwd = "";
      this.removeFrom.phone = "";
      this.removeFrom.code = "";
      this.$emit("cahngeAct", 2);
    },
    successLogin(res) {
      let obj = {
        token: res.data.token,
        hasLogin: true,
        userInfo: res.content,
      };
      this.$store.commit("loginInfo", obj);
      this.$emit("changeAlert", false);
      this.$router.go(0);
    },
    async Userlogin() {
      let obj = {
        password: this.sonUserFrom.password,
        username: this.sonUserFrom.username,
      };
      console.log(obj);
      let res = await sonLogin(obj);
      if (res.resultCode !== "200") {
        this.$Message.error(res.resultDesc);
        return;
      }
      this.$Message.success("登录成功");
      this.successLogin(res);
    },
    async codeLogin() {
      if (!this.formInline.getCodePhone) {
        this.destroy()
        this.formInline.code = ''
        this.formInline.isVerify = false
        this.$Message.warning("手机号不能为空");
        return;
      }
      if (!this.formInline.isVerify) {
        this.$Message.warning("请拖动滑块进行验证");
        return;
      }
      if (!this.formInline.code) {
        this.$Message.warning("验证码不能为空");
        return;
      }
      let obj = {
        username: this.formInline.getCodePhone,
        validateCode: this.formInline.code,
      };
      let res = await login(obj);
      if (res.resultCode !== "200") {
        this.destroy()
        this.formInline.code = ''
        this.formInline.isVerify = false
        this.$Message.error(res.resultDesc);
        return;
      }
      this.$Message.success("登录成功");
      this.successLogin(res);
    },
    async register() {
      if (this.regForm.phone == "") {
        this.destroy()
        this.regForm.code = ''
        this.regForm.isVerify = false
        this.$Message.error("手机号不能为空");
        return;
      }
      if (this.regForm.pwd == "") {
        this.destroy()
        this.regForm.code = ''
        this.regForm.isVerify = false
        this.$Message.error("密码不能为空");
        return;
      }
      if (!this.regForm.isVerify) {
        this.$Message.warning("请拖动滑块进行验证");
        return;
      }
      if (this.regForm.code == "") {
        this.$Message.error("验证码不能为空");
        return;
      }
      if (!this.single) {
        this.$Message.error("请勾选协议");
        return;
      }
      let obj = {
        password: this.regForm.pwd,
        username: this.regForm.phone,
        validateCode: this.regForm.code,
      };
      let res = await reg(obj);
      if (res.resultCode == "200") {
        this.$Message.success(res.resultDesc);
        this.$emit("cahngeAct", 1);
        this.setAct(1);
      } else if (res.resultCode !== "1009") {
        this.destroy()
        this.regForm.code = ''
        this.regForm.isVerify = false
        this.$Message.error(res.resultDesc);
        return;
      } else {
        this.destroy()
        this.regForm.code = ''
        this.regForm.isVerify = false
      }
      // console.log(res);
    },
    setSingle() {
      this.single = !this.single;
    },
    async login() {
      if (this.formInline.phone == "") {
        this.$refs.passwordLoginVerify.resetStatus()
        this.$Message.error("手机号不能为空");
        return;
      }
      if (this.formInline.password == "") {
        this.$refs.passwordLoginVerify.resetStatus()
        this.$Message.error("密码不能为空");
        return;
      }
      if (!this.formInline.isVerify) {
        this.$Message.warning("请拖动滑块进行验证");
        return;
      }
      let obj = {
        password: this.formInline.password,
        username: this.formInline.phone,
      };
      let res = await login(obj);
      if (res.resultCode !== "200") {
        this.$refs.passwordLoginVerify.resetStatus()
        this.$Message.error(res.resultDesc);
        return;
      }
      this.$Message.success("登录成功");
      // console.log(res);
      this.successLogin(res);
      this.formInline.phone = "";
      this.formInline.password = "";
    },
    destroy() {
      clearInterval(this.timer);
      this.keyFlag = true;
      this.keyButton = "获取验证码";
    },
    async sendCode(type) {
      let obj = {
        phone:
          type === "login"
            ? this.formInline.getCodePhone
            : type === "retrieve_pwd"
            ? this.removeFrom.phone
            : this.regForm.phone,
        adapter: type,
      };
      const { resultCode, resultDesc } = await getCode(obj);
      if (resultCode === '200') {
        this.$Message.success('获取成功')
        this.keyFlag = !this.keyFlag;
        let num = 60;
        this.keyButton = `${num}秒后重新获取`;
        this.timer = setInterval(() => {
          if (num <= 0) {
            this.destroy();
          } else {
            this.keyButton = `${--num}秒后重新获取`;
          }
        }, 1000);
      } else {
        this.$Message.warning(resultDesc)
      }
    },
    // 获取验证码
    getCode(type) {
      // 验证码校验规则
      const pattern = /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/;
      if (this.keyFlag) {
        if (type === "login") { // 手机号登录获取验证码
          if (this.formInline.getCodePhone.length === 0) {
            this.$Message.warning("手机号不能为空");
            return;
          }
          if (!pattern.test(this.formInline.getCodePhone)) {
            this.$Message.error("手机号格式错误");
            return;
          }
        } else if (type === "regiter") { // 注册获取验证码
          if (this.regForm.phone.length === 0) {
            this.$Message.error("手机号不能为空");
            return;
          }
          if (!pattern.test(this.regForm.phone)) {
            this.$Message.error("手机号格式错误");
            return;
          }
          if (this.regForm.pwd == "") {
            this.$Message.error("密码不能为空");
            return;
          }
        } else if (type === "retrieve_pwd") { // 忘记密码获取验证码
          if (this.removeFrom.phone.length === 0) {
            this.$Message.warning("手机号不能为空");
            return;
          }
          if (!pattern.test(this.removeFrom.phone)) {
            this.$Message.error("手机号格式错误");
            return;
          }
        }
        this.sendCode(type);
      }
    },
    setAct(i) {
      this.resetVerify();
      this.contentAct = i;
    },
    goLogin() {
      this.$emit("cahngeAct", 1);
    },
    handleVerifySuccess() {
      this.formInline.isVerify = true;
    },
    handleVerifySuccess1() {
      this.regForm.isVerify = true;
    },
    handleVerifySuccess2() {
      this.removeFrom.isVerify = true;
    },
    // 重置滑块验证
    resetVerify() {
      this.formInline.isVerify = false;
      this.removeFrom.isVerify = false;
      this.sonUserFrom.isVerify = false;
      this.regForm.isVerify = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 300px;
  margin: 0 auto;

  .top {
    width: 300px;
    display: flex;
    margin-top: 15px;
    align-items: center;
    font-size: 24px;

    .act {
      text-align: center;
      color: #00A4FF;
      padding-bottom: 6px;
      border-bottom: 2px #00A4FF solid;
      cursor: pointer;
    }

    div {
      text-align: center;
      line-height: 30px;
      margin-right: 35px;
      padding-bottom: 8px;
    }
  }

  .regiter {
    position: relative;
    margin-top: 30px;

    .getPwd {
      right: 0;
      cursor: pointer;
      //bottom: -20px;
      position: absolute;
      width: 50px;
      height: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
      line-height: 24px;
    }

    .sub {
      width: 300px;
      height: 50px;
      background: #00a4ff;
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      //margin-top: 77px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .phoneDiv1 {
      margin-bottom: 20px;
      width: 300px;
      display: flex;
      //background: pink;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f8f8f8;
      font-size: 14px;

      .img {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }

      .input {
        width: 190px;
        height: 30px;
        position: relative;

        input {
          width: 160px;
          border: 1px solid #999999;
          border-radius: 8px;
          padding-left: 10px;
          //border: none;
          height: 30px;
          outline: none;
          &::-webkit-input-placeholder {
            color: #aab2bd;
          }
        }

        .getCode {
          cursor: pointer;
          //border: 1px solid #999;
          height: 30px;
          // width: 110px;
          color: #fff;
          background: #00a4ff;
          border-radius: 8px;
          text-align: center;
          line-height: 30px;
          position: absolute;
          right: -110px;
          top: 0px;
        }
      }
    }

    .phoneDiv {
      margin-bottom: 20px;
      width: 300px;
      display: flex;
      //background: pink;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 300px;
        height: 30px;

        ::v-deep .ivu-input,
        input {
          border: 1px solid #999999;
          border-radius: 8px;
          padding-left: 10px;
          width: 300px;
          height: 30px;
          outline: none;
          &::-webkit-input-placeholder {
            color: #aab2bd;
          }
        }
        
        .no-autofill-pwd {
          ::v-deep .ivu-input-default {
            -webkit-text-security:disc!important;
          }
        }
      }

      .img {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .tits {
      width: 300px;
      text-align: right;
      margin-top: 20px;
      //height: 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 24px;

      span {
        color: #00a4ff;
        cursor: pointer;
      }
    }

    .tiaokuan {
      width: 180px;
      height: 13px;
      font-size: 12px;
      //font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 22px;
      // margin-left: 17px;

      span {
        color: #00a4ff;
      }
    }
  }

  .pwdLogin {
    position: relative;
    margin-top: 40px;

    .getPwd {
      right: 0;
      //bottom: -20px;
      position: absolute;
      cursor: pointer;
      //width: 50px;
      height: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00a4ff;
      line-height: 24px;
      top: 228px;
    }

    .sub {
      margin-bottom: 40px;
      width: 300px;
      height: 50px;
      background: #00a4ff;
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      margin-top: 10px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .phoneDiv1 {
      margin-bottom: 20px;
      width: 300px;
      display: flex;
      //background: pink;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f8f8f8;
      font-size: 14px;

      .input {
        width: 190px;
        height: 30px;
        position: relative;

        input {
          border: 1px solid #999999;
          border-radius: 8px;
          padding-left: 10px;
          width: 180px;
          height: 30px;
          outline: none;
          &::-webkit-input-placeholder {
            color: #aab2bd;
          }
        }

        .getCode {
          cursor: pointer;
          //border: 1px solid #999;
          height: 30px;
          // width: 110px;
          background: #00a4ff;
          border-radius: 8px;
          color: #fff;
          text-align: center;
          line-height: 30px;
          position: absolute;

          right: -110px;
          top: 0px;
        }
      }
    }

    .phoneDiv {
      margin-bottom: 20px;
      width: 300px;
      display: flex;
      //background: pink;
      align-items: center;
      justify-content: space-between;
      .input {
        width: 300px;
        height: 30px;

        ::v-deep .ivu-input {
          border: 1px solid #999999;
          border-radius: 8px;
          padding-left: 10px;
          width: 300px;
          height: 30px;
          outline: none;
          &::-webkit-input-placeholder {
            color: #aab2bd;
          }
        }
        .elHidden {
          width: 0;
          opacity: 0;
        }
        //input.hover{
        //  border: none;
        //}
      }

      .img {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .codeLogin {
    position: relative;
    margin-top: 40px;

    .getPwd {
      right: 0;
      //bottom: -20px;
      position: absolute;
      cursor: pointer;
      //width: 50px;
      height: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00a4ff;
      line-height: 24px;
      top: 185px;
    }

    .sub {
      margin-bottom: 40px;
      width: 300px;
      height: 50px;
      background: #00a4ff;
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      margin-top: 10px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .phoneDiv {
      margin-bottom: 20px;
      width: 300px;
      display: flex;
      //background: pink;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;

      .input {
        width: 270px;
        height: 30px;
        position: relative;

        input {
          border: 1px solid #999999;
          border-radius: 8px;
          padding-left: 10px;
          width: 300px;
          height: 30px;
          outline: none;
          &::-webkit-input-placeholder {
            color: #aab2bd;
          }
        }
        

      }
      .getCode {
        height: 30px;
        background: #00a4ff;
        border-radius: 8px;
        color: #fff;
        text-align: center;
        line-height: 30px;
      }

      .img {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}
</style>
